exports.doors = [
   {
      name: "Mnet Studio - Trệt",
      ip: "192.168.1.101",
   },
   {
      name: "VP L2 - Cửa chính",
      ip: "192.168.1.102",
   },
   {
      name: "VP L2 - Cửa phụ",
      ip: "192.168.1.104",
   },
   {
      name: "VP L2 - Cửa sau",
      ip: "192.168.1.100",
   },
   {
      name: "VP L3 - Cửa chính",
      ip: "192.168.1.103",
   },
   {
      name: "VP L3 - Cửa phụ",
      ip: "192.168.1.105",
   },
   {
      name: "VP L3 - Cửa sau",
      ip: "192.168.1.107",
   },
   {
      name: "MCVGO-L4",
      ip: "192.168.1.116",
   },
   {
      name: "Kho",
      ip: "192.168.1.114",
   },
   {
      name: "P.Kỹ Thuật",
      ip: "192.168.1.109",
   },
   {
      name: "P. Control 1 - Sau",
      ip: "192.168.1.112",
   },
   {
      name: "P. Control 1 - Trước",
      ip: "192.168.1.201",
   },
   {
      name: "P. Control 2",
      ip: "192.168.1.111",
   },
   {
      name: "P. Control 3",
      ip: "192.168.1.108",
   },
   {
      name: "Gate 1",
      ip: "192.168.1.91",
   },
   {
      name: "Gate 2",
      ip: "192.168.1.89",
   }
]